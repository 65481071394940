<template>
    <div>
        <!-- CAROUSEL-->
    <!-- <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide       
                v-if="resources!=null"         
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row>
     -->
        <br>

        <!-- <h1 class="titles">
          <span style="color: #d82125">M</span>
          <span style="color: #d02175">u</span>
          <span style="color: #f6c921">n</span>
          <span style="color: #d02175">i</span>
          <span style="color: #4d944a">c</span>
          <span style="color: #07aaba">i</span>
          <span style="color: #d82125">p</span>
          <span style="color: #d02175">i</span>
          <span style="color: #f6c921">o</span>
        </h1> -->

<h1 class="titles">
          <span style="color: #8b8c8c">Feria de la Obsidiana</span>
        </h1>



        <br>

         <b-row align-h="center">
              <b-col cols="11" md="8" lg="5" xl="5"  class="mb-5" style="height:380px;">
                  <div style="height:100%; width: 100%;">
                    <b-embed
                      class="c-shadow-sm"
                      style="width:100%; height:380px;"
                      type="iframe"
                      src="https://www.youtube.com/embed/gYeZ7l5FUck"
                    ></b-embed>
                  </div>
                </b-col>
                
              </b-row>

   <br><br>   

        
        <h1 class="titles">
          <span style="color: #8b8c8c">Fiestas Patronales</span>
        </h1>



        <br><br>

        <br>
                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/5%20fiestas/fiestas.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/5%20fiestas/fiestas1.jpg"></b-carousel-slide>
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/5%20fiestas/fiestas2.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>

    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
                        <p>
                          <b>
                            Son celebraciones anuales en cada localidad, en ellas se conmemora a sus Santos pertenecientes a cada comunidad, en las cuales se realizan festividades tradicionales como la danza de Santiagos y Alchileos, exposición de Santos de comunidades aledañas a la que se lleva a cabo la celebración. Al igual la tradicional quema de castillo.
                          </b>
                        </p>
                      </b-col>
                    </b-row>

<br><br>
    <b-row  align-h="center" class="mt-4" v-for="(item, idx) in datos " :key="idx">

        <b-col cols="10" sm="8" md="5" lg="4">
          <b-row v-if="item.comunidad!=''"><h6><b>Comunidad: {{item.comunidad}}</b></h6></b-row>
          <b-row><h6><b>Festividad: {{item.nombre}}</b></h6></b-row>
          <b-row><h6><b>Día: {{item.dia}}  Mes: {{item.mes}}</b></h6></b-row>
          
        </b-col>
    </b-row>


<h1 class="titles">
          <span style="color: #8b8c8c">Danzas</span>
        </h1>



        <br><br>
        <h5>Santiagos</h5>
        <br>
                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/5%20fiestas/santiagos.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>

    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
                        <p>
                          <b>
                            Se presentan principalmente en las ferias patronales de las comunidades. Tuvo su origen alrededor del siglo XII en la península ibérica, su temática se basa en la lucha de España por la reconquista de los espacios cristianos invadidos por los musulmanes. Al llegar los españoles a América imponen a los vencidos su cultura de conquista. 
                          </b> 
                        </p>
                        <p>
                          <b>La elección de esta danza se hizo con plena conciencia del grupo evangelizador, su auge fue tal que sobrevive hasta nuestros días en varias de las regiones del país. A partir de 1538, año en que ocurre la primera representación de esta danza en la ciudad de México, se va difundiendo por toda la Nueva España, en donde pasa a ser parte importante de la fiesta titular dedicada al santo patrón de cada comunidad cristiana.</b>
                        </p>
                      </b-col>
                    </b-row>

<br><br>
        <h5>Alchileos</h5>
        <br>
                    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5" xl="4" >
                        <b-carousel :interval="3000" controls indicators >
                          <b-carousel-slide img-src="https://www.teotihuacan.gob.mx/turismot/5%20fiestas/alchileos.jpg"></b-carousel-slide>
                        </b-carousel>
                      </b-col>
                    </b-row>

    <b-row align-h="center">
                      <b-col cols="11" md="9" lg="5"  xl="4" class="mt-4">
                        <p>
                          <b>
                            La Danza de los Alchileos es una de las tantas obras del teatro náhuatl mexicano, una de las primeras se podría decir.  Esta representación tradicional de San Francisco Mazapa, trata de la venida del Apóstol Santiago a Jerusalén Roma a vengar la muerte de Jesucristo en la persona de Pilatos,  según relatos medievales basados en historias bíblicas, misioneros, vidas de santos y comedias moralizadoras.
                          </b>
                        </p>
                        <p>
                          <b>
                            Al igual que en la comunidad mencionada, se presentan de diversas comunidades.
                          </b>
                        </p>
                      </b-col>
                    </b-row>



    </div>
</template>
<script>
  export default {
    components: {
    },
    mounted: function(){
      
    },
    computed: {
      
    },
    data() {
      return {
        resources:null,
        datos:[
         {img:'',comunidad:'ACATITLA ',nombre:'CRISTO REY',mes:'NOVIEMBRE ',dia:'',tipo:''},
//{img:'',comunidad:'COLATITLA',nombre:'',mes:'',dia:'',tipo:''},
{img:'',comunidad:'CAYAHUAL',nombre:'VIRGEN DE GUADALUPE ',mes:'DICIEMBRE ',dia:'12',tipo:''},
{img:'',comunidad:'PALOMAR ATLATONGO ',nombre:'ATLATONGO',mes:'JULIO ',dia:'',tipo:''},
{img:'',comunidad:'PURIFICACION ',nombre:'LA VIRGEN DE LA CANDELARIA',mes:'FEBRERO ',dia:'2',tipo:''},
{img:'',comunidad:'',nombre:'SAN JOSE ',mes:'MARZO ',dia:'19',tipo:''},
{img:'',comunidad:'',nombre:'SAN JUAN BAUITIZTA ',mes:'JUNIO',dia:'24',tipo:''},
{img:'',comunidad:'PUXTLA ',nombre:'SEÑOR DE LA COLUMNA ',mes:'MARZO ',dia:'15',tipo:''},
{img:'',comunidad:'',nombre:'DOMINGO DE RAMOS ',mes:'VAREA DE ACORDE AL CALENDARIO RELIGIOSO',dia:'',tipo:''},
{img:'',comunidad:'SAN AGUSTIN ACTIPAC ',nombre:'SAN AGUSTIN ',mes:'SEPTIEMBRE ',dia:'',tipo:''},
{img:'',comunidad:'SAN FRANCISCO MAZAPA ',nombre:'San Francisco de las cinco llagas',mes:'SEPTIRMBRE',dia:'17',tipo:''},
{img:'',comunidad:'',nombre:'SAN FRANCISCO DE ASIS ',mes:'OCTUBRE ',dia:'4',tipo:''},
{img:'',comunidad:'',nombre:'PENTECOSTES ',mes:'VAREA DE ACORDE AL CALENDARIO RELIGIOSO',dia:'',tipo:''},
{img:'',comunidad:'SAN ISIDRO ',nombre:'SAN ISIDRO LABRADOR ',mes:'MAYO ',dia:'5',tipo:''},
{img:'',comunidad:'SAN JUAN EVANGELISTA ',nombre:'SAN JUAN EVANGELISTA',mes:'DICIEMBRE ',dia:'27',tipo:''},
{img:'',comunidad:'',nombre:'SAN JUAN EVANGELISTA',mes:'MAYO ',dia:'6',tipo:''},
{img:'',comunidad:'SAN LORENZO TLALMIMILOLPAN ',nombre:'SAN LORENZO MARTIR ',mes:'AGOSTO ',dia:'10',tipo:''},
{img:'',comunidad:'',nombre:'SANTA CATARINA ',mes:'NOVIEMBRE ',dia:'25',tipo:''},
{img:'',comunidad:'SAN SEBASTIAN XOLALPAN ',nombre:'SAN SEBASTIAN MARTIR ',mes:'ENERO ',dia:'20',tipo:''},
{img:'',comunidad:'',nombre:'SAN JOSE ',mes:'MARZO ',dia:'19',tipo:''},
{img:'',comunidad:'',nombre:'DIVINA INFANTITA ',mes:'SEPTIEMBRE ',dia:'8',tipo:''},
{img:'',comunidad:'SANTA  MARIA COATLAN ',nombre:'VIRGEN DE GUADALUPE ',mes:'DICIEMBRE ',dia:'12',tipo:''},
{img:'',comunidad:'',nombre:'VIRGEN DE LA ASUNSION ',mes:'AGOSTO ',dia:'15',tipo:''},
{img:'',comunidad:'SANTAMARIA COATLAN NORTE ',nombre:'VIRGEN DE GUADALUPE ',mes:'DICIEMBRE ',dia:'12',tipo:''},
{img:'',comunidad:'SANTAMARIA COATLAN SUR ',nombre:'VIRGEN DE GUADALUPE ',mes:'DICIEMBRE ',dia:'12',tipo:''},
{img:'',comunidad:'SANTAMARIA MAQUIXCO ',nombre:'VIRGEN DEL FRESNO ',mes:'MARZO ',dia:'15',tipo:''},
{img:'',comunidad:'',nombre:'VIRGEN DE LA ASUNSION ',mes:'AGOSTO ',dia:'15',tipo:''},
{img:'',comunidad:'SANTIAGO ATLATONGO ',nombre:'VIRGEN DE LA CANDELARIA ',mes:'FEBRERO ',dia:'2',tipo:''},
{img:'',comunidad:'',nombre:'SANTIAGO APOSTOL ',mes:'JULIO ',dia:'25',tipo:''},
{img:'',comunidad:'SANTIAGO ZACALUCA ',nombre:'VIRGEN DE GUADALUPE ',mes:'ENERO ',dia:'12',tipo:''},
{img:'',comunidad:'',nombre:'SANTIAGO APOSTOL ',mes:'JULIO ',dia:'25',tipo:''},
{img:'',comunidad:'TEOTIHUACAN DE ARISTA ',nombre:'DIVINO REDENTOR ',mes:'JULIO ',dia:'TERCER DOMINGO JULIO ',tipo:''},


        ]
      }
    }
    
  }
</script>